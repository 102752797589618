import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../authService';
import { Observable, of } from 'rxjs';
import { FileService } from './fileService';
import { UniCache } from '@app/cache';
import { switchMap, tap } from 'rxjs/operators';
import { File } from '@uni-entities';
import { CompanySettingsService } from './companySettingsService';

@Injectable({ providedIn: 'root' })
export class UniFilesService {
    private uniFilesBaseUrl: string = environment.BASE_URL_FILES;
    private activeCompany: any;

    private ehfDataCache = new UniCache(0, 50);

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private fileService: FileService,
        private companySettingsService: CompanySettingsService,
    ) {
        this.authService.authentication$.subscribe((authDetails) => {
            if (authDetails) {
                this.activeCompany = authDetails.activeCompany;
            }
        });
    }

    upload(file, entityType?: string, entityID?: number) {
        return this.fileService.upload(file, entityType, entityID);
    }

    getEhfData(file: File) {
        const loadFromAp = file.ID === 0;

        const endpoint = file.Deleted ? 'api/download/deleted' : 'api/download';
        const url = `${this.uniFilesBaseUrl}/${endpoint}?format=json&id=${file.StorageReference}${loadFromAp ? '&loadFromAp=true' : ''}`;
        const cached = this.ehfDataCache.get(url);

        if (cached) {
            return of(cached);
        } else {
            const companySettingsRequest = loadFromAp ? this.companySettingsService.getCompanySettings() : of(null);

            return companySettingsRequest.pipe(
                switchMap((companySettings) => {
                    const secret = companySettings?.APGuid || '';
                    return this.http.get(url, {
                        headers: {
                            Key: this.activeCompany.Key,
                            'x-uni-secret': secret,
                        },
                    });
                }),
                tap((ehfData) => this.ehfDataCache.add(url, ehfData)),
            );
        }
    }

    public forceFullLoad(id: string): Observable<any> {
        return this.http.get(this.uniFilesBaseUrl + '/api/file/force-full-load/' + id, {
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }

    public getFileSplitList(id: string): Observable<any> {
        return this.http.get(this.uniFilesBaseUrl + '/api/file/get-page-split-info/' + id, {
            observe: 'body',
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }

    public rotate(id: string, page: number, rotateClockwise: boolean): Observable<any> {
        this.fileService.invalidateCacheForStorageReference(id);
        return this.http.get(this.uniFilesBaseUrl + `/api/file/rotate-page/${id}/${page}/${rotateClockwise}`, {
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }

    public getFileProcessingStatus(id: string): Observable<any> {
        return this.http.get(this.uniFilesBaseUrl + '/api/file/filestatus/' + id, {
            observe: 'body',
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }

    runOcr(id: string, forceNewAnalysis: boolean = false): Observable<any> {
        return this.http.get(
            this.uniFilesBaseUrl + '/api/ocr/analyze?forceNewAnalysis=' + forceNewAnalysis + '&id=' + id,
            {
                observe: 'body',
                headers: {
                    Key: this.activeCompany.Key,
                },
            },
        );
    }

    public getOcrStatistics(): Observable<any> {
        return this.http.get(this.uniFilesBaseUrl + '/api/client/get-ocr-stats', {
            observe: 'body',
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }

    public trainOcrEngine(body) {
        this.http
            .post(this.uniFilesBaseUrl + '/api/ocr/train-engine', body, {
                headers: {
                    Key: this.activeCompany.Key,
                },
            })
            .subscribe(
                () => {},
                () => {},
            );
    }

    public removeUsedTemplate(supplierOrgNo, bankAccountNumber): Observable<any> {
        const params = [];
        if (supplierOrgNo) {
            params.push('organizationNumber=' + supplierOrgNo);
        }
        if (bankAccountNumber) {
            params.push('bankAccountNumber=' + bankAccountNumber);
        }

        return this.http.post(this.uniFilesBaseUrl + '/api/ocr/removeusedtemplate?' + params.join('&'), null, {
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }

    public splitFileMultiple(
        fileStorageReference,
        batches: IFileSplitMultipleBatch[],
        rotations: IFileRotation[],
    ): Observable<any> {
        const url = this.uniFilesBaseUrl + `/api/file/split-multiple?id=${fileStorageReference}`;
        const body = JSON.stringify({ Batches: batches, Rotations: rotations });
        return this.http.post(url, body, {
            observe: 'body',
            headers: {
                'Content-Type': 'application/json',
                Key: this.activeCompany.Key,
            },
        });
    }

    public splitFile(fileStorageReference, fromPage): Observable<any> {
        const url = this.uniFilesBaseUrl + `/api/file/split?id=${fileStorageReference}&frompage=${fromPage}`;
        return this.http.post(url, null, {
            observe: 'body',
            headers: {
                Key: this.activeCompany.Key,
            },
        });
    }
}

export interface IFileSplitMultipleBatch {
    Pages: Array<number>;
}

export interface IFileRotation {
    Page: number;
    Rotation: number;
}
