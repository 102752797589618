import { refCount, publishReplay, map, take, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { UniHttp } from '../../../framework/core/http/http';
import { forkJoin, Observable, of } from 'rxjs';
import { ElsaProductType, ElsaPurchase, ElsaPurchaseStatus } from '@app/models';
import { cloneDeep } from 'lodash-es';
import { CompanySettingsService } from '../common/companySettingsService';
import { IAuthDetails } from '@app/authService';
import { ElsaAgreementService } from './elsaAgreementService';
import { ElsaProductService } from './elsaProductService';
import { UserRoleService } from '../common/userRoleService';

@Injectable({ providedIn: 'root' })
export class ElsaPurchaseService {
    private cache: { [endpoint: string]: Observable<HttpResponse<any>> } = {};

    constructor(
        private uniHttp: UniHttp,
        private companySettingsService: CompanySettingsService,
        private userRoleService: UserRoleService,
        private elsaProductService: ElsaProductService,
        private elsaAgreementService: ElsaAgreementService,
    ) {
        this.uniHttp.authService.authentication$.subscribe(() => {
            this.invalidateCache();
        });
    }

    invalidateCache() {
        this.cache = {};
    }

    getPurchaseByProductName(productName: string): Observable<ElsaPurchase> {
        return this.getAll(`ProductName=${productName}`).pipe(
            map((res) => (res && res[0]) || null),
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
        );
    }

    getPurchasesByPurchaseStatus(purchaseStatus: ElsaPurchaseStatus): Observable<ElsaPurchase[]> {
        return this.getAll(`PurchaseStatus=${purchaseStatus}`).pipe(
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
        );
    }

    getPurchaseByProductID(productID: number): Observable<ElsaPurchase> {
        return this.getAll(`ProductID=${productID}`).pipe(
            map((res) => (res && res[0]) || null),
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
        );
    }

    getContractPurchase(contractID: number, productID?: number) {
        let endpoint = `/api/purchases/contract/${contractID}`;
        if (productID) {
            endpoint += `?$filter=ProductID eq ${productID}`;
        }
        return this.uniHttp.asGET().usingElsaDomain().withEndPoint(endpoint).send();
    }

    getAll(odata?: string, companyKeyOverride?: string): Observable<ElsaPurchase[]> {
        let endpoint = 'api/elsa/purchases';
        if (odata) {
            endpoint += '?' + odata;
        }

        const cacheKey = endpoint + (companyKeyOverride || '');
        let cachedRequest = this.cache[cacheKey];

        if (!cachedRequest) {
            if (companyKeyOverride) {
                this.uniHttp.withHeader('CompanyKey', companyKeyOverride);
            } else {
                this.uniHttp.withDefaultHeaders();
            }

            cachedRequest = this.uniHttp
                .asGET()
                .usingEmptyDomain()
                .withEndPoint(endpoint)
                .send({}, null, companyKeyOverride ? false : true)
                .pipe(publishReplay(1), refCount());

            this.cache[cacheKey] = cachedRequest;
        }

        return cachedRequest.pipe(
            take(1),
            map((res) => cloneDeep(res.body)),
        );
    }

    massUpdate(updates: ElsaPurchase[], companyKeyOverride?: string) {
        this.invalidateCache();

        // default roles are automatically assigned when purchasing a product
        // product roles that are not in use by a different product/purchase is removed when deactivating
        this.userRoleService.invalidateCache();

        if (companyKeyOverride) {
            this.uniHttp.withHeader('CompanyKey', companyKeyOverride);
        } else {
            this.uniHttp.withDefaultHeaders();
        }

        return this.uniHttp
            .asPUT()
            .usingEmptyDomain()
            .withEndPoint('api/elsa/purchases')
            .withBody(updates)
            .send({}, null, companyKeyOverride ? false : true)
            .pipe(map((res) => res.status === 200));
    }

    activateProduct(productId: number) {
        this.invalidateCache();

        // Because activating some products causes changes to companysettings
        this.companySettingsService.invalidateCache();

        // default roles are automatically assigned when purchasing a product
        this.userRoleService.invalidateCache();

        return this.uniHttp
            .asPUT()
            .usingEmptyDomain()
            .withEndPoint(`api/elsa/activate/${productId}`)
            .send()
            .pipe(map((res) => res.status === 200 || res.status === 204));
    }

    cancelPurchase(productId: number) {
        this.invalidateCache();

        // Because deactivating some products causes changes to companysettings
        this.companySettingsService.invalidateCache();

        // product roles that are not in use by a different product/purchase is removed when deactivating
        this.userRoleService.invalidateCache();

        return this.uniHttp
            .asDELETE()
            .usingEmptyDomain()
            .withEndPoint(`api/elsa/cancelpurchase/${productId}`)
            .send()
            .pipe(map((res) => res.status === 200));
    }

    getUserPurchases(userIdentity: string): Observable<ElsaPurchase[]> {
        const endpoint = `api/elsa/userlicenses/purchases?userIdentity=${userIdentity}`;
        let cachedRequest = this.cache[endpoint];

        if (!cachedRequest) {
            cachedRequest = this.uniHttp
                .asGET()
                .usingEmptyDomain()
                .withEndPoint(endpoint)
                .send()
                .pipe(publishReplay(1), refCount());

            this.cache[endpoint] = cachedRequest;
        }

        return cachedRequest.pipe(
            take(1),
            map((res) => cloneDeep(res.body)),
        );
    }

    getUserActivePurchases(userIdentity: string): Observable<ElsaPurchase[]> {
        const endpoint = `api/elsa/userlicenses/active-purchases?userIdentity=${userIdentity}`;

        let cachedRequest = this.cache[endpoint];

        if (!cachedRequest) {
            cachedRequest = this.uniHttp
                .asGET()
                .usingEmptyDomain()
                .withEndPoint(endpoint)
                .send()
                .pipe(publishReplay(1), refCount());

            this.cache[endpoint] = cachedRequest;
        }

        return cachedRequest.pipe(
            take(1),
            map((res) => {
                return cloneDeep(res.body);
            }),
        );
    }

    getUserPurchasesOnContract(
        userGuid,
        contractID,
    ): Observable<
        {
            PurchaseID: number;
            CompanyName: string;
            CompanyKey: string;
            ProductName: string;
            UserName: string;
            Email: string;
        }[]
    > {
        const params = [`globalUser=${userGuid}`, `contractid=${contractID}`];

        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/Purchases/on-contract?${params.join('&')}`)
            .send()
            .pipe(map((res) => res.body));
    }

    checkPurchasesWithConsentRequired(authDetails: IAuthDetails): Observable<any> {
        return forkJoin([
            this.userRoleService.hasAdminRole(authDetails.user.ID),
            this.getAll(`PurchaseStatus=${ElsaPurchaseStatus.ConsentRequired}`),
        ]).pipe(
            switchMap(([isAdmin, consentPurchases]) => {
                if (isAdmin && consentPurchases?.length) {
                    const purchase = consentPurchases[0];
                    const contractType = authDetails.user.License.ContractType.TypeID;
                    const productFilter = `id eq ${purchase.ProductID}`;
                    const productSelect = 'id,name,label,producttype';
                    return forkJoin([
                        this.elsaProductService.getProductsOnContractTypes(contractType, productFilter, productSelect),
                        this.elsaAgreementService.GetByProductID(purchase.ProductID),
                    ]).pipe(
                        map(([products, agreement]) => {
                            if (
                                products?.length &&
                                products[0]?.ProductType === ElsaProductType.AccountingAgencyCompany
                            ) {
                                return { agreement: agreement || null, product: products[0] };
                            }
                            return null;
                        }),
                    );
                }
                return of(null);
            }),
            catchError(() => of(null)),
        );
    }
}
